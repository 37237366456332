import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Url.module.css';

Url.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Url.defaultProps = {
  type: 'Webseite',
};

export function Url({ url, type }) {
  let urlWithoutProtocol = url.replace('http://', '').replace('https://', '');

  if (urlWithoutProtocol.endsWith('/')) {
    urlWithoutProtocol = urlWithoutProtocol.substring(0, urlWithoutProtocol.length - 1);
  }

  if (type === 'Email') {
    return (
      <a href={`mailto:${url}`} className={styles.Url}>
        {url}
      </a>
    );
  }
  return (
    <a href={url} target="_blank" className={styles.Url} rel="noopener">
      {urlWithoutProtocol}
    </a>
  );
}
