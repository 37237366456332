import React from 'react';
import PropTypes from 'prop-types';

import { Url } from './Url';

import * as styles from './Link.module.css';

Link.propTypes = {
  name: PropTypes.string.isRequired,
  published: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Link.defaultProps = {};

export function Link({ name, published, links }) {
  if (!published || !links || links.length === 0) {
    return null;
  }

  return (
    <div className={styles.Link}>
      <span>{name}</span>
      {links && links.length > 0 && (
        <ul key={name}>
          {links.map((link) => (
            <li key={link.url}>
              <Url {...link} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
