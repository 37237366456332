import React from 'react';
import PropTypes from 'prop-types';

import { Section } from 'Components/Section';
import { Link } from './Link';

import * as styles from './LinksSection.module.css';

LinksSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      published: PropTypes.bool,
      category: PropTypes.shape({
        category: PropTypes.object,
      }),
      links: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export function LinksSection({ data }) {
  const { title, subtitle } = data[0].category;
  return (
    <Section className={styles.LinkSection} title={title} subtitle={subtitle} columns={2}>
      {data.map((link) => (
        <Link {...link} key={link.id} />
      ))}
    </Section>
  );
}
