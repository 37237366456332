import React from 'react';
import { graphql } from 'gatsby';

import { Links } from '../blocks/Links';
import { DefaultLayout } from '../layouts/Default';

const InteressanteThemenPage = ({ data, location }) => {
  const { links } = data;
  return (
    <DefaultLayout>
      <Links data={links} />
    </DefaultLayout>
  );
};

export default InteressanteThemenPage;

export const InteressanteThemenPageQuery = graphql`
  query InteressanteThemenPageQuery {
    links: allCockpitLink {
      edges {
        node {
          id
          name
          published
          links {
            url
            type
          }
          category {
            id
            title
            subtitle
          }
        }
      }
    }
  }
`;
