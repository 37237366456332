import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { slug } from '@mangoart/gatsby-ui/helper/Slugger';

import * as styles from './Section.module.css';

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.string,
  className: PropTypes.string,
};
Section.defaultProps = {
  title: null,
  subtitle: null,
  columns: 1,
  align: 'Links',
  className: '',
};

export function Section({ title, subtitle, children, columns, align, className, id }) {
  if (!children) {
    return null;
  }

  const sectionClasses = clsx(styles.Section, styles[`column${columns}`], styles[`align${align}`], className);

  return (
    <section className={sectionClasses} id={id}>
      <span className="anchor" id={slug(title)}></span>
      {title && (
        <h2>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </h2>
      )}
      <div>{children}</div>
    </section>
  );
}
