import React from 'react';
import PropTypes from 'prop-types';

import { LinksSection } from 'Components/LinksSection';

import * as styles from './Links.module.css';

Links.propTypes = {
  data: PropTypes.shape({}),
};

Links.defaultProps = {
  data: null,
};

const splitLinksByCategory = (links) => {
  const splitByCategory = {};

  links.forEach(({ node }) => {
    const categoryId = node.category.id;
    splitByCategory[categoryId] = splitByCategory[categoryId] || [];
    splitByCategory[categoryId].push(node);
  });

  return splitByCategory;
};

export function Links({ data }) {
  if (!data || !data.edges || data.edges.length === 0) {
    return null;
  }

  const splitByCategory = splitLinksByCategory(data.edges);

  return (
    <div className="defaultWidth">
      {Object.keys(splitByCategory).map((categoryId) => {
        return <LinksSection data={splitByCategory[categoryId]} key={categoryId} />;
      })}
    </div>
  );
}
